import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import { FacebookProvider, Comments } from "react-facebook";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import addCustomLinks from "../utils/addCustomLinks";

const postInfo = (post) => {
    if (post.tags.length) {
        return (
            <span className="post-info">
                <span className="post-type">Article</span>
                <span className="post-count">{post.tags[0].name}</span>
            </span>
        );
    }
};

const getTags = (post) => {
    if (post.tags.length) {
        return (
            <aside className="post-tags">
                <span className="post-info-label">Topic</span>
                {post.tags.map((tag, i) => {
                    return (
                        <a key={tag.slug} href={`/tag/${tag.slug}`}>
                            {tag.name}
                        </a>
                    );
                })}
            </aside>
        );
    }
};

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const author = post.authors[0];
    const publishDate = post.published_at;

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <header className="post-header has-cover">
                    <div className="inner">
                        {postInfo(post)}
                        <h1 className="post-title">{post.title}</h1>

                        <div className="post-meta">
                            <div className="post-meta-avatars">
                                <figure className="post-meta-avatar avatar">
                                    <a
                                        href="/author/ghost/"
                                        className="author-avatar"
                                    >
                                        <img
                                            className="author-profile-image"
                                            src={author.profile_image}
                                            alt={author.name}
                                        />
                                    </a>
                                </figure>
                            </div>
                            <h4 className="post-meta-author">
                                <a href="/author/ghost/">{author.name}</a>
                            </h4>
                            <time dateTime={post.published_at}>
                                {post.published_at_pretty}
                            </time>{" "}
                            • {post.reading_time} min read
                        </div>
                        <div
                            className="post-cover cover"
                            style={{ transform: "translate3d(0px, 0px, 0px)" }}
                        >
                            <img src={post.feature_image} alt={post.title} />
                        </div>
                    </div>
                </header>
                <main className="content" role="main">
                    <article className="post">
                        <div className="inner">
                            <section className="post-content">
                                {addCustomLinks(post.html)}
                            </section>

                            <section className="post-footer">
                                <div className="post-share">
                                    <span className="post-info-label">
                                        Share
                                    </span>
                                    <a
                                        title="Twitter"
                                        className="twitter"
                                        href={`https://twitter.com/share?text=${post.title}&amp;url=https://godcangodcares.com/${post.slug}`}
                                        onClick={() => {
                                            window.open(
                                                this.href,
                                                "twitter-share",
                                                "width=550,height=235"
                                            );
                                            return false;
                                        }}
                                    >
                                        <i className="icon icon-twitter"></i>
                                        <span className="hidden">Twitter</span>
                                    </a>
                                    <a
                                        title="Facebook"
                                        className="facebook fbc-has-badge fbc-UID_1"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https://godcangodcares.com/${post.slug}`}
                                        onClick={() => {
                                            window.open(
                                                this.href,
                                                "facebook-share",
                                                "width=580,height=296"
                                            );
                                            return false;
                                        }}
                                    >
                                        <i className="icon icon-facebook"></i>
                                        <span className="hidden">Facebook</span>
                                    </a>
                                    <a
                                        title="LinkedIn"
                                        className="linkedin"
                                        href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=https://godcangodcares.com/${post.slug}&amp;title=${post.title}`}
                                        onClick={() => {
                                            window.open(
                                                this.href,
                                                "linkedin-share",
                                                "width=930,height=720"
                                            );
                                            return false;
                                        }}
                                    >
                                        <i className="icon icon-linkedin"></i>
                                        <span className="hidden">LinkedIn</span>
                                    </a>
                                    <a
                                        title="Email"
                                        className="email"
                                        href={`mailto:?subject=${post.title} - God Can. God Cares.&amp;body=https://godcangodcares.com/${post.slug}`}
                                    >
                                        <i className="icon icon-mail"></i>
                                        <span className="hidden">Email</span>
                                    </a>
                                </div>
                                {getTags(post)}
                            </section>

                            <Link className="subscribe-button" to="/subscribe">
                                Subscribe for updates!
                            </Link>
                            <div className={"fb-comments-container"}>
                                <FacebookProvider appId="324803251815080">
                                    <Comments
                                        href={`https://godcangodcares.com/${post.slug}`}
                                    />
                                </FacebookProvider>
                            </div>
                        </div>
                    </article>
                </main>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
