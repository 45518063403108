import axios from "axios";

const baseUrl = "https://api.esv.org/v3/passage/html/?q=";

export default (url) => {
    let search = new URLSearchParams(new URL(url).search).get("search");
    return axios
        .get(`${baseUrl}${search}`, {
            headers: {
                Authorization: "33c213eeea94db97fe12d23583fc09c579b68b85",
            },
        })
        .then(({ data }) => {
            return data.passages;
        })
        .catch((e) => {
            console.error(e);
        });
};
