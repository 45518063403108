import React from "react";
import BibleVerse from "../components/common/BibleVerse";

import { Parser, ProcessNodeDefinitions } from "html-to-react";

// Because API
const isValidNode = function () {
    return true;
};

const processingInstructions = [
    {
        // Custom <a> processing
        shouldProcessNode: function (node) {
            return (
                node.name === "a" &&
                node.attribs.href.indexOf("biblegateway") >= 0
            );
        },
        processNode: function (node, children) {
            return <BibleVerse url={node.attribs.href}>{children}</BibleVerse>;
        },
    },
    {
        // Anything else
        shouldProcessNode: function (node) {
            return true;
        },
        processNode: ProcessNodeDefinitions(React).processDefaultNode,
    },
];

export default (html) => {
    let htmlToReactParser = new Parser();
    return htmlToReactParser.parseWithInstructions(
        html,
        isValidNode,
        processingInstructions
    );
};
