import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import crosswayFetch from "../../utils/crosswayFetch";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const Passage = ({ passages }) => {
    return passages.map((i, index) => {
        return <div key={index} dangerouslySetInnerHTML={{ __html: i }} />;
    });
};

const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
};

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        "max-width": 800,
        width: "80vw",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: "scroll",
        "max-height": "75vh",
        "font-size": "2rem",
        "line-height": "3rem",
        "font-family": "'Crimson Text', serif",
        margin: 0,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function BibleVerse({ url, children }) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);

    const [verseComponent, setVerseComponent] = useState(<CircularProgress />);

    const handleOpen = (event) => {
        let { currentTarget } = event;
        event.preventDefault();

        crosswayFetch(url).then((passages) => {
            setOpen(true);
            setVerseComponent(<Passage passages={passages} />);
        });
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <a href={url} aria-haspopup="true" onClick={handleOpen}>
                {children}
            </a>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                    {verseComponent}
                </div>
            </Modal>
        </>
    );
}
